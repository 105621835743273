import React, { useState, useEffect } from "react";
import closeBtnIcon from "../../assets/img/x.webp";
import kalenderIcon from "../../assets/img/kalender_icon.webp";
import locationIcon from "../../assets/img/standort_icon.webp";
import themeIcon from "../../assets/img/Themen_Icon.webp";
import EventForm from "./Forms/EventForm";

function EventModal({ Props, Actions, closeModalFunction }) {
  const [activePageIndex, setActivePageIndex] = useState(0);
  const [activePage, setActivePage] = useState([]);
  const maxPageIndex = 2;
  const closeModal = Actions["closeModal"];

  useEffect(
    (closeModal) => {
      const onBtnInfoClick = () => {
        if (activePageIndex < maxPageIndex) {
          setActivePageIndex(activePageIndex + 1);
        } else {
          closeModalFunction();
        }
      };

      const RenderInfoPage = () => {
        return (
          <>
            <h1 className="modal-event-heading fw-bold">
              Veranstaltungsanmeldung
            </h1>
            <div className="modal-event-sub-heading">
              <h2>4. Dialogreihe MiWi Lausitz</h2>
              <h2></h2>
            </div>

            <div className="modal-event-info">
              <div className="modal-event-info-wrapper">
                <img className="modal-icon" src={kalenderIcon} alt="kalender" />
                <p className="modal-event-info-text">
                  19.03.2025, ab 14:30 Uhr
                </p>
              </div>
              <div className="modal-event-info-wrapper">
                <img className="modal-icon" src={locationIcon} alt="standort" />
                <div className="modal-event-info-address">
                  <span>Tagungshaus ElsterPark</span>
                  <span>04916 Herzberg (Elster)</span>
                </div>
              </div>
              <div className="modal-event-infolist-wrapper">
                {/* 
                <img className="modal-icon" src={themeIcon} alt="themen" />
                <ul className="modal-event-infolist">
                  <li>
                    Weitere Informationen zur Themenauswahl erfolgen in Kürze
                  </li>
                </ul>
                */}
              </div>
            </div>
            <div className="btn" onClick={onBtnInfoClick}>
              Weiter
            </div>
          </>
        );
      };

      const RenderNotificationPage = () => {
        return (
          <div className="modal-confirmation-info">
            <p>Herzlichen Dank für ihre Anmeldung.</p>
            <p>
              Hiermit bestätigen wir Ihre Teilnahme an der 4. MiWi Lausitz am
              19. März 2025 ab 14:30 Uhr. Die Veranstaltung findet im
              Tagungshaus ElsterPark in Herzberg (Elster) statt.
            </p>
            <p>
              Sollte diese Anmeldung irrtümlich erfolgt sein, teilen Sie uns
              dies bitte umgehend unter veranstaltung@an-sb.de mit.
            </p>
            <div className="btn" onClick={onBtnInfoClick}>
              Schließen
            </div>
          </div>
        );
      };

      switch (activePageIndex) {
        case 0:
          return setActivePage(RenderInfoPage());

        case 1:
          return setActivePage(
            <EventForm AfterSubmitAction={onBtnInfoClick} />
          );

        case 2:
          return setActivePage(RenderNotificationPage());

        default:
          return setActivePage(<></>);
      }
    },
    [activePageIndex]
  );

  return (
    <div className="modal">
      <span className="modal-btn-close">
        <img src={closeBtnIcon} alt="anmelde_icon" onClick={closeModal} />
      </span>
      <div className="modal-event-body">{activePage}</div>
    </div>
  );
}

export default EventModal;
